<template>
  <main class="app__page article">
    <div class="article__container">
      <h1 class="article__title title">
        {{ title || $t("pages.privacy.title") }}
      </h1>
      <div class="article__content-wrapper">
        <div class="article__circles-left"></div>
        <div class="article__circles-right"></div>
        <div class="article__content content" v-html="content"></div>
      </div>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "privacy",
  components: { PageFooter },
  mixins: [pageLoaded],
  data() {
    return {
      loadQty: 2, // смонтировано + апи
      title: "",
      content: ""
    };
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    locale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getPage() {
      this.$axios({
        method: "get",
        url: this.api + "/static-pages/privacy",
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            const data = response.data;
            if (data.title) this.title = data.title;
            if (data.content) this.content = data.content;
          } else {
            this.$toast.error(this.$t("error"));
          }

          this.loaded = this.loaded + 1;
        })
        .catch((error) => {
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
          this.loaded = this.loaded + 1;
        });
    }
  },
  created() {
    this.getPage();
  },
  watch: {
    locale() {
      this.getPage();
    }
  }
};
</script>
